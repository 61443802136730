import React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const FormInput = ({
  labelText,
  value,
  name,
  type,
  placeholder,
  handleChange,
  disabled,
  min,
  max,
  className,
  isShown,
  togglePassword,
}) => {
  return (
    <div className={`form-row text-black ${className}`}>
      <label htmlFor="" className="form-label">
        {labelText ? labelText : name}
      </label>
      <div className="relative">
        <input
          onChange={handleChange}
          type={type}
          className="form-input relative "
          name={name}
          value={value}
          placeholder={placeholder && placeholder}
          disabled={disabled}
          min={min}
          max={max}
          // valueAsNumber={true}
        />
        {(isShown === true || isShown === false) && (
          <div
            onClick={togglePassword}
            className="inline absolute  right-6 text-xl top-4"
          >
            {isShown ? <FaEyeSlash /> : <FaEye />}
          </div>
        )}
      </div>
    </div>
  );
};
export default FormInput;
